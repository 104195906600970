export const useIsEditor = (): boolean => {
  const { query } = useRoute()
  return computed(() => (query._storyblok ? true : false)).value
}
export const useIsEditorClass = (): string => {
  return useIsEditor()
    ? 'relative before:text-xs before:text-white before:rounded before:bg-black before:p-1 before:px-2 before:absolute before:top-2 before:left-2 before:content-["Only_visible_in_admin"]'
    : ''
}

export const useIsVisible = (blok: { hide: boolean }): boolean => {
  if (blok && blok.hide) {
    if (useIsEditor()) {
      return true
    }

    return false
  }
  return true
}
